import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex cursor-pointer items-center gap-2" };
const _hoisted_2 = ["name", "value", "checked"];
const _hoisted_3 = { class: "flex flex-col" };
const _hoisted_4 = { class: "text-base/6 md:text-xs/6" };
const _hoisted_5 = { key: 0, class: "mt-0.5 text-red-500" };
import { toRefs } from 'vue';
import { useField } from 'vee-validate';
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-checkbox', props: { label: {}, name: {}, modelValue: { type: [Boolean, String, null, Object, Array] }, value: { type: [Boolean, String, null, Object], default: true }, uncheckedValue: { type: [Boolean, String, null], default: false }, required: { type: Boolean, default: false } }, setup(__props) {
        const props = __props;
        const { name, required } = toRefs(props);
        const rules = required.value ? 'required' : undefined;
        const { handleChange, checked, errorMessage } = useField(name, rules, { type: 'checkbox', checkedValue: props.value, uncheckedValue: props.uncheckedValue, initialValue: props.modelValue });
        return (_ctx, _cache) => {
            return _openBlock(), _createElementBlock("label", _hoisted_1, [_createElementVNode("input", _mergeProps({ name: _unref(name), value: _ctx.value, checked: _unref(checked), type: "checkbox", class: "form-checkbox m-1 size-6 rounded border border-slate-500 bg-slate-300/20 transition-all duration-200 ease-in-out checked:border-blue-500 checked:bg-blue-500 hover:bg-slate-500 focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-1 md:size-4" }, _ctx.$attrs, { onChange: _cache[0] || (_cache[0] = //@ts-ignore
                        (...args) => _unref(handleChange) && _unref(handleChange)(...args)) }), null, 16, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1), _unref(errorMessage) ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(errorMessage)), 1)) : _createCommentVNode("", true)])]);
        };
    } });
