import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full overflow-hidden rounded-md border border-slate-700 bg-slate-900 focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-1 focus-within:ring-offset-transparent" };
const _hoisted_2 = ["value"];
import { useDebounceFn } from '@vueuse/core';
const DEBOUNCE_DELAY = 500;
export default /*@__PURE__*/ _defineComponent({ __name: 'base-search-input', props: { modelValue: {} }, emits: ["update:modelValue"], setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const debouncedEmit = useDebounceFn(event => emit('update:modelValue', event.target.value), DEBOUNCE_DELAY);
        return (_ctx, _cache) => {
            return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", _mergeProps(_ctx.$attrs, { value: props.modelValue, class: "w-full rounded-md border-none bg-slate-900 px-4 py-1.5 text-sm/6 text-slate-200 outline-none", onInput: _cache[0] || (_cache[0] = //@ts-ignore
                        (...args) => _unref(debouncedEmit) && _unref(debouncedEmit)(...args)) }), null, 16, _hoisted_2)]);
        };
    } });
